.bold { font-weight: bold !important; }
.normal { font-weight: normal !important; }
.lower { text-transform: lowercase; }
.upper { text-transform: uppercase; }
.block { display: block; }
.inline-block { display: inline-block !important; }
.align-middle { vertical-align: middle !important; }
.align-top { vertical-align: top !important; }
.align-bottom { vertical-align: bottom !important; }
.hide, .hidden { display: none !important; }
.no-shadow { box-shadow: none !important; }
.no-border { border: 0px !important; }
.no-resize { resize: none; }
.no-outline { outline: none !important; }
.cursor-pointer { cursor: pointer; }
.underline { text-decoration: underline !important; }
.underline-offset { text-decoration: underline !important; text-underline-offset: 5px; }
.no-opacity { opacity: 1 !important; }
.italic { font-style: italic; }
.h-auto { height: auto !important;}
.text-right{ text-align: right; }
.float-right{ float: right; }

.btn-border	 { border: solid 1px #cbc8c8 !important; }
.border-success	 { border: solid 1px #4cae4c !important; }
.border-warning	 { border: solid 1px #eea236 !important; }
.border-danger	 { border: solid 1px #d43f3a !important; }
.border-info	 { border: solid 1px #46b8da !important; }

.alert-success-light	{ background-color: #b2dcbc !important; }
.alert-warning-light	{ background-color: #fdedbd !important; }
.alert-danger-light		{ background-color: #fdd3d7 !important; }
.alert-info-light		{ background-color: #D3DCE3 !important; }

.border-top{ border-top: 1px solid #ccc !important; }
.border-top-dark{ border-top: 1px solid #222 !important; }
.border-bottom{ border-bottom: 1px solid #ccc !important; }
.border-dark { border: solid 1px #222 !important; }
.all-borders { border: 1px solid #e4e6ef; }

.bg-outletdee { background-color: #65b05b !important; }
.text-outletdee { color: #65b05b !important; }
.text-white { color: #fff !important; }
.text-success {color: #3c763d !important;}
.text-primary {color: #337ab7 !important;}
.text-info {color: #31708f !important;}
.text-link {color: #0804f2 !important;}
.bg-white { background-color: #fff !important; }
.bg-gray { background-color: #777777 !important; }
.bg-light-gray { background-color: #c2c2c2 !important; }
.bg-lighter-gray { background-color: #dddddd !important; }
.bg-lightblue { background-color: lightblue !important; }

.bg-offwhite { background-color: #f9f9f9 !important;}
.bg-offwhite2 { background-color: #eee !important;}
.bg-light { background-color: #f0f0f0 !important;}


.overflow { overflow: auto;}
.no-overflow { overflow: hidden;}
.no-select { user-select: none; }

.overflow-dots {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	display: -webkit-box !important;
	-webkit-box-orient: vertical !important;
}

._1line   { -webkit-line-clamp:  1; line-clamp:  1; }
._2lines  { -webkit-line-clamp:  2; line-clamp:  2; }
._3lines  { -webkit-line-clamp:  3; line-clamp:  3; }
._4lines  { -webkit-line-clamp:  4; line-clamp:  4; }
._5lines  { -webkit-line-clamp:  5; line-clamp:  5; }
._6lines  { -webkit-line-clamp:  6; line-clamp:  6; }
._7lines  { -webkit-line-clamp:  7; line-clamp:  7; }
._8lines  { -webkit-line-clamp:  8; line-clamp:  8; }
._9lines  { -webkit-line-clamp:  9; line-clamp:  9; }
._10lines { -webkit-line-clamp: 10; line-clamp: 10; }
._11lines { -webkit-line-clamp: 11; line-clamp: 11; }
._12lines { -webkit-line-clamp: 12; line-clamp: 12; }
._13lines { -webkit-line-clamp: 13; line-clamp: 13; }
._14lines { -webkit-line-clamp: 14; line-clamp: 14; }
._15lines { -webkit-line-clamp: 15; line-clamp: 15; }
._16lines { -webkit-line-clamp: 16; line-clamp: 16; }
._17lines { -webkit-line-clamp: 17; line-clamp: 17; }
._18lines { -webkit-line-clamp: 18; line-clamp: 18; }
._19lines { -webkit-line-clamp: 19; line-clamp: 19; }
._20lines { -webkit-line-clamp: 20; line-clamp: 20; }

.vertical-center{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.lh20 { line-height: 20px;}
.lh21 { line-height: 21px;}
.lh22 { line-height: 22px;}
.lh23 { line-height: 23px;}
.lh24 { line-height: 24px;}
.lh25 { line-height: 25px;}
.lh26 { line-height: 26px;}
.lh27 { line-height: 27px;}
.lh28 { line-height: 28px;}
.lh29 { line-height: 29px;}
.lh30 { line-height: 30px;}
.lh31 { line-height: 31px;}
.lh32 { line-height: 32px;}
.lh33 { line-height: 33px;}
.lh34 { line-height: 34px;}

.m0i  { margin: 0px !important; }
.mt0i { margin-top: 0px !important; }
.ml0i { margin-left: 0px !important; }
.mb0i { margin-bottom: 0px !important; }
.mr0i { margin-right: 0px !important; }

.p0i  { padding: 0px !important; }
.pt0i { padding-top: 0px !important; }
.pl0i { padding-left: 0px !important; }
.pb0i { padding-bottom: 0px !important; }
.pr0i { padding-right: 0px !important; }

/***** Rounded Corner Short Codes *****/
.rounded5  { border-radius:  5px !important; }
.rounded10 { border-radius: 10px !important; }
.rounded15 { border-radius: 15px !important; }
.rounded20 { border-radius: 20px !important; }
.rounded25 { border-radius: 25px !important; }
.rounded30 { border-radius: 30px !important; }

.roundedTop5  { border-radius:  5px  5px 0px 0px !important; }
.roundedTop10 { border-radius: 10px 10px 0px 0px !important; }
.roundedTop15 { border-radius: 15px 15px 0px 0px !important; }
.roundedTop20 { border-radius: 20px 20px 0px 0px !important; }
.roundedTop25 { border-radius: 25px 25px 0px 0px !important; }
.roundedTop30 { border-radius: 30px 30px 0px 0px !important; }

.roundedBottom5  { border-radius: 0px 0px  5px  5px !important; }
.roundedBottom10 { border-radius: 0px 0px 10px 10px !important; }
.roundedBottom15 { border-radius: 0px 0px 15px 15px !important; }
.roundedBottom20 { border-radius: 0px 0px 20px 20px !important; }
.roundedBottom25 { border-radius: 0px 0px 25px 25px !important; }
.roundedBottom30 { border-radius: 0px 0px 30px 30px !important; }

/***** Margin Short Codes *****/
.m0 { margin: 0px !important; }
.m1 { margin: 1px !important; }
.m2 { margin: 2px !important; }
.m3 { margin: 3px !important; }
.m4 { margin: 4px !important; }
.m5 { margin: 5px !important; }
.m10 { margin: 10px !important; }
.m15 { margin: 15px !important; }
.m20 { margin: 20px !important; }
.m25 { margin: 25px !important; }
.m30 { margin: 30px !important; }
.m35 { margin: 35px !important; }
.m40 { margin: 40px !important; }
.m45 { margin: 45px !important; }
.m50 { margin: 50px !important; }
.m55 { margin: 55px !important; }
.m60 { margin: 60px !important; }
.m65 { margin: 65px !important; }
.m70 { margin: 70px !important; }
.m75 { margin: 75px !important; }
.m80 { margin: 80px !important; }
.m85 { margin: 85px !important; }
.m90 { margin: 90px !important; }
.m95 { margin: 95px !important; }
.m100 { margin: 100px !important; }
.m105 { margin: 105px !important; }
.m110 { margin: 110px !important; }
.m115 { margin: 115px !important; }
.m120 { margin: 120px !important; }
.m125 { margin: 125px !important; }
.m130 { margin: 130px !important; }
.m135 { margin: 135px !important; }
.m140 { margin: 140px !important; }
.m145 { margin: 145px !important; }
.m150 { margin: 150px !important; }
.m155 { margin: 155px !important; }
.m160 { margin: 160px !important; }
.m165 { margin: 165px !important; }
.m170 { margin: 170px !important; }
.m175 { margin: 175px !important; }
.m180 { margin: 180px !important; }
.m185 { margin: 185px !important; }
.m190 { margin: 190px !important; }
.m195 { margin: 195px !important; }
.m200 { margin: 200px !important; }
.m205 { margin: 205px !important; }
.m210 { margin: 210px !important; }
.m215 { margin: 215px !important; }
.m220 { margin: 220px !important; }
.m225 { margin: 225px !important; }
.m230 { margin: 230px !important; }
.m235 { margin: 235px !important; }
.m240 { margin: 240px !important; }
.m245 { margin: 245px !important; }
.m250 { margin: 250px !important; }
.m255 { margin: 255px !important; }
.m260 { margin: 260px !important; }
.m265 { margin: 265px !important; }
.m270 { margin: 270px !important; }
.m275 { margin: 275px !important; }
.m280 { margin: 280px !important; }
.m285 { margin: 285px !important; }
.m290 { margin: 290px !important; }
.m295 { margin: 295px !important; }
.m300 { margin: 300px !important; }

/****************************/

.mt0 { margin-top: 0px !important; }
.mt1 { margin-top: 1px !important; }
.mt2 { margin-top: 2px !important; }
.mt3 { margin-top: 3px !important; }
.mt4 { margin-top: 4px !important; }
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt45 { margin-top: 45px !important; }
.mt50 { margin-top: 50px !important; }
.mt55 { margin-top: 55px !important; }
.mt60 { margin-top: 60px !important; }
.mt65 { margin-top: 65px !important; }
.mt70 { margin-top: 70px !important; }
.mt75 { margin-top: 75px !important; }
.mt80 { margin-top: 80px !important; }
.mt85 { margin-top: 85px !important; }
.mt90 { margin-top: 90px !important; }
.mt95 { margin-top: 95px !important; }
.mt100 { margin-top: 100px !important; }
.mt105 { margin-top: 105px !important; }
.mt110 { margin-top: 110px !important; }
.mt115 { margin-top: 115px !important; }
.mt120 { margin-top: 120px !important; }
.mt125 { margin-top: 125px !important; }
.mt130 { margin-top: 130px !important; }
.mt135 { margin-top: 135px !important; }
.mt140 { margin-top: 140px !important; }
.mt145 { margin-top: 145px !important; }
.mt150 { margin-top: 150px !important; }
.mt155 { margin-top: 155px !important; }
.mt160 { margin-top: 160px !important; }
.mt165 { margin-top: 165px !important; }
.mt170 { margin-top: 170px !important; }
.mt175 { margin-top: 175px !important; }
.mt180 { margin-top: 180px !important; }
.mt185 { margin-top: 185px !important; }
.mt190 { margin-top: 190px !important; }
.mt195 { margin-top: 195px !important; }
.mt200 { margin-top: 200px !important; }
.mt205 { margin-top: 205px !important; }
.mt210 { margin-top: 210px !important; }
.mt215 { margin-top: 215px !important; }
.mt220 { margin-top: 220px !important; }
.mt225 { margin-top: 225px !important; }
.mt230 { margin-top: 230px !important; }
.mt235 { margin-top: 235px !important; }
.mt240 { margin-top: 240px !important; }
.mt245 { margin-top: 245px !important; }
.mt250 { margin-top: 250px !important; }
.mt255 { margin-top: 255px !important; }
.mt260 { margin-top: 260px !important; }
.mt265 { margin-top: 265px !important; }
.mt270 { margin-top: 270px !important; }
.mt275 { margin-top: 275px !important; }
.mt280 { margin-top: 280px !important; }
.mt285 { margin-top: 285px !important; }
.mt290 { margin-top: 290px !important; }
.mt295 { margin-top: 295px !important; }
.mt300 { margin-top: 300px !important; }

/*****************************/

.mr0 { margin-right: 0px !important; }
.mr1 { margin-right: 1px !important; }
.mr2 { margin-right: 2px !important; }
.mr3 { margin-right: 3px !important; }
.mr4 { margin-right: 4px !important; }
.mr5 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }
.mr25 { margin-right: 25px !important; }
.mr30 { margin-right: 30px !important; }
.mr35 { margin-right: 35px !important; }
.mr40 { margin-right: 40px !important; }
.mr45 { margin-right: 45px !important; }
.mr50 { margin-right: 50px !important; }
.mr55 { margin-right: 55px !important; }
.mr60 { margin-right: 60px !important; }
.mr65 { margin-right: 65px !important; }
.mr70 { margin-right: 70px !important; }
.mr75 { margin-right: 75px !important; }
.mr80 { margin-right: 80px !important; }
.mr85 { margin-right: 85px !important; }
.mr90 { margin-right: 90px !important; }
.mr95 { margin-right: 95px !important; }
.mr100 { margin-right: 100px !important; }
.mr105 { margin-right: 105px !important; }
.mr110 { margin-right: 110px !important; }
.mr115 { margin-right: 115px !important; }
.mr120 { margin-right: 120px !important; }
.mr125 { margin-right: 125px !important; }
.mr130 { margin-right: 130px !important; }
.mr135 { margin-right: 135px !important; }
.mr140 { margin-right: 140px !important; }
.mr145 { margin-right: 145px !important; }
.mr150 { margin-right: 150px !important; }
.mr155 { margin-right: 155px !important; }
.mr160 { margin-right: 160px !important; }
.mr165 { margin-right: 165px !important; }
.mr170 { margin-right: 170px !important; }
.mr175 { margin-right: 175px !important; }
.mr180 { margin-right: 180px !important; }
.mr185 { margin-right: 185px !important; }
.mr190 { margin-right: 190px !important; }
.mr195 { margin-right: 195px !important; }
.mr200 { margin-right: 200px !important; }
.mr205 { margin-right: 205px !important; }
.mr210 { margin-right: 210px !important; }
.mr215 { margin-right: 215px !important; }
.mr220 { margin-right: 220px !important; }
.mr225 { margin-right: 225px !important; }
.mr230 { margin-right: 230px !important; }
.mr235 { margin-right: 235px !important; }
.mr240 { margin-right: 240px !important; }
.mr245 { margin-right: 245px !important; }
.mr250 { margin-right: 250px !important; }
.mr255 { margin-right: 255px !important; }
.mr260 { margin-right: 260px !important; }
.mr265 { margin-right: 265px !important; }
.mr270 { margin-right: 270px !important; }
.mr275 { margin-right: 275px !important; }
.mr280 { margin-right: 280px !important; }
.mr285 { margin-right: 285px !important; }
.mr290 { margin-right: 290px !important; }
.mr295 { margin-right: 295px !important; }
.mr300 { margin-right: 300px !important; }

/*****************************/

.mb0 { margin-bottom: 0px !important; }
.mb1 { margin-bottom: 1px !important; }
.mb2 { margin-bottom: 2px !important; }
.mb3 { margin-bottom: 3px !important; }
.mb4 { margin-bottom: 4px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb45 { margin-bottom: 45px !important; }
.mb50 { margin-bottom: 50px !important; }
.mb55 { margin-bottom: 55px !important; }
.mb60 { margin-bottom: 60px !important; }
.mb65 { margin-bottom: 65px !important; }
.mb70 { margin-bottom: 70px !important; }
.mb75 { margin-bottom: 75px !important; }
.mb80 { margin-bottom: 80px !important; }
.mb85 { margin-bottom: 85px !important; }
.mb90 { margin-bottom: 90px !important; }
.mb95 { margin-bottom: 95px !important; }
.mb100 { margin-bottom: 100px !important; }
.mb105 { margin-bottom: 105px !important; }
.mb110 { margin-bottom: 110px !important; }
.mb115 { margin-bottom: 115px !important; }
.mb120 { margin-bottom: 120px !important; }
.mb125 { margin-bottom: 125px !important; }
.mb130 { margin-bottom: 130px !important; }
.mb135 { margin-bottom: 135px !important; }
.mb140 { margin-bottom: 140px !important; }
.mb145 { margin-bottom: 145px !important; }
.mb150 { margin-bottom: 150px !important; }
.mb155 { margin-bottom: 155px !important; }
.mb160 { margin-bottom: 160px !important; }
.mb165 { margin-bottom: 165px !important; }
.mb170 { margin-bottom: 170px !important; }
.mb175 { margin-bottom: 175px !important; }
.mb180 { margin-bottom: 180px !important; }
.mb185 { margin-bottom: 185px !important; }
.mb190 { margin-bottom: 190px !important; }
.mb195 { margin-bottom: 195px !important; }
.mb200 { margin-bottom: 200px !important; }
.mb205 { margin-bottom: 205px !important; }
.mb210 { margin-bottom: 210px !important; }
.mb215 { margin-bottom: 215px !important; }
.mb220 { margin-bottom: 220px !important; }
.mb225 { margin-bottom: 225px !important; }
.mb230 { margin-bottom: 230px !important; }
.mb235 { margin-bottom: 235px !important; }
.mb240 { margin-bottom: 240px !important; }
.mb245 { margin-bottom: 245px !important; }
.mb250 { margin-bottom: 250px !important; }
.mb255 { margin-bottom: 255px !important; }
.mb260 { margin-bottom: 260px !important; }
.mb265 { margin-bottom: 265px !important; }
.mb270 { margin-bottom: 270px !important; }
.mb275 { margin-bottom: 275px !important; }
.mb280 { margin-bottom: 280px !important; }
.mb285 { margin-bottom: 285px !important; }
.mb290 { margin-bottom: 290px !important; }
.mb295 { margin-bottom: 295px !important; }
.mb300 { margin-bottom: 300px !important; }

/*****************************/

.ml0 { margin-left: 0px !important; }
.ml1 { margin-left: 1px !important; }
.ml2 { margin-left: 2px !important; }
.ml3 { margin-left: 3px !important; }
.ml4 { margin-left: 4px !important; }
.ml5 { margin-left: 5px !important; }
.ml10 { margin-left: 10px !important; }
.ml15 { margin-left: 15px !important; }
.ml20 { margin-left: 20px !important; }
.ml25 { margin-left: 25px !important; }
.ml30 { margin-left: 30px !important; }
.ml35 { margin-left: 35px !important; }
.ml40 { margin-left: 40px !important; }
.ml45 { margin-left: 45px !important; }
.ml50 { margin-left: 50px !important; }
.ml55 { margin-left: 55px !important; }
.ml60 { margin-left: 60px !important; }
.ml65 { margin-left: 65px !important; }
.ml70 { margin-left: 70px !important; }
.ml75 { margin-left: 75px !important; }
.ml80 { margin-left: 80px !important; }
.ml85 { margin-left: 85px !important; }
.ml90 { margin-left: 90px !important; }
.ml95 { margin-left: 95px !important; }
.ml100 { margin-left: 100px !important; }
.ml105 { margin-left: 105px !important; }
.ml110 { margin-left: 110px !important; }
.ml115 { margin-left: 115px !important; }
.ml120 { margin-left: 120px !important; }
.ml125 { margin-left: 125px !important; }
.ml130 { margin-left: 130px !important; }
.ml135 { margin-left: 135px !important; }
.ml140 { margin-left: 140px !important; }
.ml145 { margin-left: 145px !important; }
.ml150 { margin-left: 150px !important; }
.ml155 { margin-left: 155px !important; }
.ml160 { margin-left: 160px !important; }
.ml165 { margin-left: 165px !important; }
.ml170 { margin-left: 170px !important; }
.ml175 { margin-left: 175px !important; }
.ml180 { margin-left: 180px !important; }
.ml185 { margin-left: 185px !important; }
.ml190 { margin-left: 190px !important; }
.ml195 { margin-left: 195px !important; }
.ml200 { margin-left: 200px !important; }
.ml205 { margin-left: 205px !important; }
.ml210 { margin-left: 210px !important; }
.ml215 { margin-left: 215px !important; }
.ml220 { margin-left: 220px !important; }
.ml225 { margin-left: 225px !important; }
.ml230 { margin-left: 230px !important; }
.ml235 { margin-left: 235px !important; }
.ml240 { margin-left: 240px !important; }
.ml245 { margin-left: 245px !important; }
.ml250 { margin-left: 250px !important; }
.ml255 { margin-left: 255px !important; }
.ml260 { margin-left: 260px !important; }
.ml265 { margin-left: 265px !important; }
.ml270 { margin-left: 270px !important; }
.ml275 { margin-left: 275px !important; }
.ml280 { margin-left: 280px !important; }
.ml285 { margin-left: 285px !important; }
.ml290 { margin-left: 290px !important; }
.ml295 { margin-left: 295px !important; }
.ml300 { margin-left: 300px !important; }

/***** Padding Short Codes *****/

.p0 { padding: 0px !important; }
.p1 { padding: 1px !important; }
.p2 { padding: 2px !important; }
.p3 { padding: 3px !important; }
.p4 { padding: 4px !important; }
.p5 { padding: 5px !important; }
.p10 { padding: 10px !important; }
.p15 { padding: 15px !important; }
.p20 { padding: 20px !important; }
.p25 { padding: 25px !important; }
.p30 { padding: 30px !important; }
.p35 { padding: 35px !important; }
.p40 { padding: 40px !important; }
.p45 { padding: 45px !important; }
.p50 { padding: 50px !important; }
.p55 { padding: 55px !important; }
.p60 { padding: 60px !important; }
.p65 { padding: 65px !important; }
.p70 { padding: 70px !important; }
.p75 { padding: 75px !important; }
.p80 { padding: 80px !important; }
.p85 { padding: 85px !important; }
.p90 { padding: 90px !important; }
.p95 { padding: 95px !important; }
.p100 { padding: 100px !important; }
.p105 { padding: 105px !important; }
.p110 { padding: 110px !important; }
.p115 { padding: 115px !important; }
.p120 { padding: 120px !important; }
.p125 { padding: 125px !important; }
.p130 { padding: 130px !important; }
.p135 { padding: 135px !important; }
.p140 { padding: 140px !important; }
.p145 { padding: 145px !important; }
.p150 { padding: 150px !important; }
.p155 { padding: 155px !important; }
.p160 { padding: 160px !important; }
.p165 { padding: 165px !important; }
.p170 { padding: 170px !important; }
.p175 { padding: 175px !important; }
.p180 { padding: 180px !important; }
.p185 { padding: 185px !important; }
.p190 { padding: 190px !important; }
.p195 { padding: 195px !important; }
.p200 { padding: 200px !important; }
.p205 { padding: 205px !important; }
.p210 { padding: 210px !important; }
.p215 { padding: 215px !important; }
.p220 { padding: 220px !important; }
.p225 { padding: 225px !important; }
.p230 { padding: 230px !important; }
.p235 { padding: 235px !important; }
.p240 { padding: 240px !important; }
.p245 { padding: 245px !important; }
.p250 { padding: 250px !important; }
.p255 { padding: 255px !important; }
.p260 { padding: 260px !important; }
.p265 { padding: 265px !important; }
.p270 { padding: 270px !important; }
.p275 { padding: 275px !important; }
.p280 { padding: 280px !important; }
.p285 { padding: 285px !important; }
.p290 { padding: 290px !important; }
.p295 { padding: 295px !important; }
.p300 { padding: 300px !important; }

/*****************************/

.pt0 { padding-top: 0px !important; }
.pt1 { padding-top: 1px !important; }
.pt2 { padding-top: 2px !important; }
.pt3 { padding-top: 3px !important; }
.pt4 { padding-top: 4px !important; }
.pt5 { padding-top: 5px !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; }
.pt30 { padding-top: 30px !important; }
.pt35 { padding-top: 35px !important; }
.pt40 { padding-top: 40px !important; }
.pt45 { padding-top: 45px !important; }
.pt50 { padding-top: 50px !important; }
.pt55 { padding-top: 55px !important; }
.pt60 { padding-top: 60px !important; }
.pt65 { padding-top: 65px !important; }
.pt70 { padding-top: 70px !important; }
.pt75 { padding-top: 75px !important; }
.pt80 { padding-top: 80px !important; }
.pt85 { padding-top: 85px !important; }
.pt90 { padding-top: 90px !important; }
.pt95 { padding-top: 95px !important; }
.pt100 { padding-top: 100px !important; }
.pt105 { padding-top: 105px !important; }
.pt110 { padding-top: 110px !important; }
.pt115 { padding-top: 115px !important; }
.pt120 { padding-top: 120px !important; }
.pt125 { padding-top: 125px !important; }
.pt130 { padding-top: 130px !important; }
.pt135 { padding-top: 135px !important; }
.pt140 { padding-top: 140px !important; }
.pt145 { padding-top: 145px !important; }
.pt150 { padding-top: 150px !important; }
.pt155 { padding-top: 155px !important; }
.pt160 { padding-top: 160px !important; }
.pt165 { padding-top: 165px !important; }
.pt170 { padding-top: 170px !important; }
.pt175 { padding-top: 175px !important; }
.pt180 { padding-top: 180px !important; }
.pt185 { padding-top: 185px !important; }
.pt190 { padding-top: 190px !important; }
.pt195 { padding-top: 195px !important; }
.pt200 { padding-top: 200px !important; }
.pt205 { padding-top: 205px !important; }
.pt210 { padding-top: 210px !important; }
.pt215 { padding-top: 215px !important; }
.pt220 { padding-top: 220px !important; }
.pt225 { padding-top: 225px !important; }
.pt230 { padding-top: 230px !important; }
.pt235 { padding-top: 235px !important; }
.pt240 { padding-top: 240px !important; }
.pt245 { padding-top: 245px !important; }
.pt250 { padding-top: 250px !important; }
.pt255 { padding-top: 255px !important; }
.pt260 { padding-top: 260px !important; }
.pt265 { padding-top: 265px !important; }
.pt270 { padding-top: 270px !important; }
.pt275 { padding-top: 275px !important; }
.pt280 { padding-top: 280px !important; }
.pt285 { padding-top: 285px !important; }
.pt290 { padding-top: 290px !important; }
.pt295 { padding-top: 295px !important; }
.pt300 { padding-top: 300px !important; }

/*****************************/

.pr0 { padding-right: 0px !important; }
.pr1 { padding-right: 1px !important; }
.pr2 { padding-right: 2px !important; }
.pr3 { padding-right: 3px !important; }
.pr4 { padding-right: 4px !important; }
.pr5 { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr15 { padding-right: 15px !important; }
.pr20 { padding-right: 20px !important; }
.pr25 { padding-right: 25px !important; }
.pr30 { padding-right: 30px !important; }
.pr35 { padding-right: 35px !important; }
.pr40 { padding-right: 40px !important; }
.pr45 { padding-right: 45px !important; }
.pr50 { padding-right: 50px !important; }
.pr55 { padding-right: 55px !important; }
.pr60 { padding-right: 60px !important; }
.pr65 { padding-right: 65px !important; }
.pr70 { padding-right: 70px !important; }
.pr75 { padding-right: 75px !important; }
.pr80 { padding-right: 80px !important; }
.pr85 { padding-right: 85px !important; }
.pr90 { padding-right: 90px !important; }
.pr95 { padding-right: 95px !important; }
.pr100 { padding-right: 100px !important; }
.pr105 { padding-right: 105px !important; }
.pr110 { padding-right: 110px !important; }
.pr115 { padding-right: 115px !important; }
.pr120 { padding-right: 120px !important; }
.pr125 { padding-right: 125px !important; }
.pr130 { padding-right: 130px !important; }
.pr135 { padding-right: 135px !important; }
.pr140 { padding-right: 140px !important; }
.pr145 { padding-right: 145px !important; }
.pr150 { padding-right: 150px !important; }
.pr155 { padding-right: 155px !important; }
.pr160 { padding-right: 160px !important; }
.pr165 { padding-right: 165px !important; }
.pr170 { padding-right: 170px !important; }
.pr175 { padding-right: 175px !important; }
.pr180 { padding-right: 180px !important; }
.pr185 { padding-right: 185px !important; }
.pr190 { padding-right: 190px !important; }
.pr195 { padding-right: 195px !important; }
.pr200 { padding-right: 200px !important; }
.pr205 { padding-right: 205px !important; }
.pr210 { padding-right: 210px !important; }
.pr215 { padding-right: 215px !important; }
.pr220 { padding-right: 220px !important; }
.pr225 { padding-right: 225px !important; }
.pr230 { padding-right: 230px !important; }
.pr235 { padding-right: 235px !important; }
.pr240 { padding-right: 240px !important; }
.pr245 { padding-right: 245px !important; }
.pr250 { padding-right: 250px !important; }
.pr255 { padding-right: 255px !important; }
.pr260 { padding-right: 260px !important; }
.pr265 { padding-right: 265px !important; }
.pr270 { padding-right: 270px !important; }
.pr275 { padding-right: 275px !important; }
.pr280 { padding-right: 280px !important; }
.pr285 { padding-right: 285px !important; }
.pr290 { padding-right: 290px !important; }
.pr295 { padding-right: 295px !important; }
.pr300 { padding-right: 300px !important; }

/*****************************/

.pb0 { padding-bottom: 0px !important; }
.pb1 { padding-bottom: 1px !important; }
.pb2 { padding-bottom: 2px !important; }
.pb3 { padding-bottom: 3px !important; }
.pb4 { padding-bottom: 4px !important; }
.pb5 { padding-bottom: 5px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb30 { padding-bottom: 30px !important; }
.pb35 { padding-bottom: 35px !important; }
.pb40 { padding-bottom: 40px !important; }
.pb45 { padding-bottom: 45px !important; }
.pb50 { padding-bottom: 50px !important; }
.pb55 { padding-bottom: 55px !important; }
.pb60 { padding-bottom: 60px !important; }
.pb65 { padding-bottom: 65px !important; }
.pb70 { padding-bottom: 70px !important; }
.pb75 { padding-bottom: 75px !important; }
.pb80 { padding-bottom: 80px !important; }
.pb85 { padding-bottom: 85px !important; }
.pb90 { padding-bottom: 90px !important; }
.pb95 { padding-bottom: 95px !important; }
.pb100 { padding-bottom: 100px !important; }
.pb105 { padding-bottom: 105px !important; }
.pb110 { padding-bottom: 110px !important; }
.pb115 { padding-bottom: 115px !important; }
.pb120 { padding-bottom: 120px !important; }
.pb125 { padding-bottom: 125px !important; }
.pb130 { padding-bottom: 130px !important; }
.pb135 { padding-bottom: 135px !important; }
.pb140 { padding-bottom: 140px !important; }
.pb145 { padding-bottom: 145px !important; }
.pb150 { padding-bottom: 150px !important; }
.pb155 { padding-bottom: 155px !important; }
.pb160 { padding-bottom: 160px !important; }
.pb165 { padding-bottom: 165px !important; }
.pb170 { padding-bottom: 170px !important; }
.pb175 { padding-bottom: 175px !important; }
.pb180 { padding-bottom: 180px !important; }
.pb185 { padding-bottom: 185px !important; }
.pb190 { padding-bottom: 190px !important; }
.pb195 { padding-bottom: 195px !important; }
.pb200 { padding-bottom: 200px !important; }
.pb205 { padding-bottom: 205px !important; }
.pb210 { padding-bottom: 210px !important; }
.pb215 { padding-bottom: 215px !important; }
.pb220 { padding-bottom: 220px !important; }
.pb225 { padding-bottom: 225px !important; }
.pb230 { padding-bottom: 230px !important; }
.pb235 { padding-bottom: 235px !important; }
.pb240 { padding-bottom: 240px !important; }
.pb245 { padding-bottom: 245px !important; }
.pb250 { padding-bottom: 250px !important; }
.pb255 { padding-bottom: 255px !important; }
.pb260 { padding-bottom: 260px !important; }
.pb265 { padding-bottom: 265px !important; }
.pb270 { padding-bottom: 270px !important; }
.pb275 { padding-bottom: 275px !important; }
.pb280 { padding-bottom: 280px !important; }
.pb285 { padding-bottom: 285px !important; }
.pb290 { padding-bottom: 290px !important; }
.pb295 { padding-bottom: 295px !important; }
.pb300 { padding-bottom: 300px !important; }

/*****************************/

.pl0 { padding-left: 0px !important; }
.pl1 { padding-left: 1px !important; }
.pl2 { padding-left: 2px !important; }
.pl3 { padding-left: 3px !important; }
.pl4 { padding-left: 4px !important; }
.pl5 { padding-left: 5px !important; }
.pl10 { padding-left: 10px !important; }
.pl15 { padding-left: 15px !important; }
.pl20 { padding-left: 20px !important; }
.pl25 { padding-left: 25px !important; }
.pl30 { padding-left: 30px !important; }
.pl35 { padding-left: 35px !important; }
.pl40 { padding-left: 40px !important; }
.pl45 { padding-left: 45px !important; }
.pl50 { padding-left: 50px !important; }
.pl55 { padding-left: 55px !important; }
.pl60 { padding-left: 60px !important; }
.pl65 { padding-left: 65px !important; }
.pl70 { padding-left: 70px !important; }
.pl75 { padding-left: 75px !important; }
.pl80 { padding-left: 80px !important; }
.pl85 { padding-left: 85px !important; }
.pl90 { padding-left: 90px !important; }
.pl95 { padding-left: 95px !important; }
.pl100 { padding-left: 100px !important; }
.pl105 { padding-left: 105px !important; }
.pl110 { padding-left: 110px !important; }
.pl115 { padding-left: 115px !important; }
.pl120 { padding-left: 120px !important; }
.pl125 { padding-left: 125px !important; }
.pl130 { padding-left: 130px !important; }
.pl135 { padding-left: 135px !important; }
.pl140 { padding-left: 140px !important; }
.pl145 { padding-left: 145px !important; }
.pl150 { padding-left: 150px !important; }
.pl155 { padding-left: 155px !important; }
.pl160 { padding-left: 160px !important; }
.pl165 { padding-left: 165px !important; }
.pl170 { padding-left: 170px !important; }
.pl175 { padding-left: 175px !important; }
.pl180 { padding-left: 180px !important; }
.pl185 { padding-left: 185px !important; }
.pl190 { padding-left: 190px !important; }
.pl195 { padding-left: 195px !important; }
.pl200 { padding-left: 200px !important; }
.pl205 { padding-left: 205px !important; }
.pl210 { padding-left: 210px !important; }
.pl215 { padding-left: 215px !important; }
.pl220 { padding-left: 220px !important; }
.pl225 { padding-left: 225px !important; }
.pl230 { padding-left: 230px !important; }
.pl235 { padding-left: 235px !important; }
.pl240 { padding-left: 240px !important; }
.pl245 { padding-left: 245px !important; }
.pl250 { padding-left: 250px !important; }
.pl255 { padding-left: 255px !important; }
.pl260 { padding-left: 260px !important; }
.pl265 { padding-left: 265px !important; }
.pl270 { padding-left: 270px !important; }
.pl275 { padding-left: 275px !important; }
.pl280 { padding-left: 280px !important; }
.pl285 { padding-left: 285px !important; }
.pl290 { padding-left: 290px !important; }
.pl295 { padding-left: 295px !important; }
.pl300 { padding-left: 300px !important; }


/* width short codes */

.w0 { width: 0px !important; }
.w5 { width: 5px !important; }
.w10 { width: 10px !important; }
.w15 { width: 15px !important; }
.w20 { width: 20px !important; }
.w25 { width: 25px !important; }
.w30 { width: 30px !important; }
.w35 { width: 35px !important; }
.w40 { width: 40px !important; }
.w45 { width: 45px !important; }
.w50 { width: 50px !important; }
.w55 { width: 55px !important; }
.w60 { width: 60px !important; }
.w65 { width: 65px !important; }
.w70 { width: 70px !important; }
.w75 { width: 75px !important; }
.w80 { width: 80px !important; }
.w85 { width: 85px !important; }
.w90 { width: 90px !important; }
.w95 { width: 95px !important; }
.w100 { width: 100px !important; }
.w105 { width: 105px !important; }
.w110 { width: 110px !important; }
.w115 { width: 115px !important; }
.w120 { width: 120px !important; }
.w125 { width: 125px !important; }
.w130 { width: 130px !important; }
.w135 { width: 135px !important; }
.w140 { width: 140px !important; }
.w145 { width: 145px !important; }
.w150 { width: 150px !important; }
.w155 { width: 155px !important; }
.w160 { width: 160px !important; }
.w165 { width: 165px !important; }
.w170 { width: 170px !important; }
.w175 { width: 175px !important; }
.w180 { width: 180px !important; }
.w185 { width: 185px !important; }
.w190 { width: 190px !important; }
.w195 { width: 195px !important; }
.w200 { width: 200px !important; }
.w205 { width: 205px !important; }
.w210 { width: 210px !important; }
.w215 { width: 215px !important; }
.w220 { width: 220px !important; }
.w225 { width: 225px !important; }
.w230 { width: 230px !important; }
.w235 { width: 235px !important; }
.w240 { width: 240px !important; }
.w245 { width: 245px !important; }
.w250 { width: 250px !important; }
.w255 { width: 255px !important; }
.w260 { width: 260px !important; }
.w265 { width: 265px !important; }
.w270 { width: 270px !important; }
.w275 { width: 275px !important; }
.w280 { width: 280px !important; }
.w285 { width: 285px !important; }
.w290 { width: 290px !important; }
.w295 { width: 295px !important; }
.w300 { width: 300px !important; }
.w305 { width: 305px !important; }
.w310 { width: 310px !important; }
.w315 { width: 315px !important; }
.w320 { width: 320px !important; }
.w325 { width: 325px !important; }
.w330 { width: 330px !important; }
.w335 { width: 335px !important; }
.w340 { width: 340px !important; }
.w345 { width: 345px !important; }
.w350 { width: 350px !important; }
.w355 { width: 355px !important; }
.w360 { width: 360px !important; }
.w365 { width: 365px !important; }
.w370 { width: 370px !important; }
.w375 { width: 375px !important; }
.w380 { width: 380px !important; }
.w385 { width: 385px !important; }
.w390 { width: 390px !important; }
.w395 { width: 395px !important; }
.w400 { width: 400px !important; }

.mw0  { min-width: 0px !important; }
.mw5  { min-width: 5px !important; }
.mw10 { min-width: 10px !important; }
.mw15 { min-width: 15px !important; }
.mw20 { min-width: 20px !important; }
.mw25 { min-width: 25px !important; }
.mw30 { min-width: 30px !important; }
.mw35 { min-width: 35px !important; }
.mw40 { min-width: 40px !important; }
.mw45 { min-width: 45px !important; }
.mw50 { min-width: 50px !important; }
.mw55 { min-width: 55px !important; }
.mw60 { min-width: 60px !important; }
.mw65 { min-width: 65px !important; }
.mw70 { min-width: 70px !important; }
.mw75 { min-width: 75px !important; }
.mw80 { min-width: 80px !important; }
.mw85 { min-width: 85px !important; }
.mw90 { min-width: 90px !important; }
.mw95 { min-width: 95px !important; }
.mw100 { min-width: 100px !important; }
.mw105 { min-width: 105px !important; }
.mw110 { min-width: 110px !important; }
.mw115 { min-width: 115px !important; }
.mw120 { min-width: 120px !important; }
.mw125 { min-width: 125px !important; }
.mw130 { min-width: 130px !important; }
.mw135 { min-width: 135px !important; }
.mw140 { min-width: 140px !important; }
.mw145 { min-width: 145px !important; }
.mw150 { min-width: 150px !important; }
.mw155 { min-width: 155px !important; }
.mw160 { min-width: 160px !important; }
.mw165 { min-width: 165px !important; }
.mw170 { min-width: 170px !important; }
.mw175 { min-width: 175px !important; }
.mw180 { min-width: 180px !important; }
.mw185 { min-width: 185px !important; }
.mw190 { min-width: 190px !important; }
.mw195 { min-width: 195px !important; }
.mw200 { min-width: 200px !important; }
.mw205 { min-width: 205px !important; }
.mw210 { min-width: 210px !important; }
.mw215 { min-width: 215px !important; }
.mw220 { min-width: 220px !important; }
.mw225 { min-width: 225px !important; }
.mw230 { min-width: 230px !important; }
.mw235 { min-width: 235px !important; }
.mw240 { min-width: 240px !important; }
.mw245 { min-width: 245px !important; }
.mw250 { min-width: 250px !important; }
.mw255 { min-width: 255px !important; }
.mw260 { min-width: 260px !important; }
.mw265 { min-width: 265px !important; }
.mw270 { min-width: 270px !important; }
.mw275 { min-width: 275px !important; }
.mw280 { min-width: 280px !important; }
.mw285 { min-width: 285px !important; }
.mw290 { min-width: 290px !important; }
.mw295 { min-width: 295px !important; }
.mw300 { min-width: 300px !important; }


.w0p { width: 0% !important; }
.w5p { width: 5% !important; }
.w10p { width: 10% !important; }
.w15p { width: 15% !important; }
.w20p { width: 20% !important; }
.w25p { width: 25% !important; }
.w30p { width: 30% !important; }
.w33p { width: 33.33% !important; }
.w35p { width: 35% !important; }
.w40p { width: 40% !important; }
.w45p { width: 45% !important; }
.w50p { width: 50% !important; }
.w55p { width: 55% !important; }
.w60p { width: 60% !important; }
.w65p { width: 65% !important; }
.w70p { width: 70% !important; }
.w75p { width: 75% !important; }
.w80p { width: 80% !important; }
.w85p { width: 85% !important; }
.w90p { width: 90% !important; }
.w95p { width: 95% !important; }
.w100p { width: 100% !important; }
.w105p { width: 105% !important; }
.w110p { width: 110% !important; }
.w115p { width: 115% !important; }
.w120p { width: 120% !important; }
.w125p { width: 125% !important; }
.w130p { width: 130% !important; }
.w135p { width: 135% !important; }
.w140p { width: 140% !important; }
.w145p { width: 145% !important; }
.w150p { width: 150% !important; }
.w155p { width: 155% !important; }
.w160p { width: 160% !important; }
.w165p { width: 165% !important; }
.w170p { width: 170% !important; }
.w175p { width: 175% !important; }
.w180p { width: 180% !important; }
.w185p { width: 185% !important; }
.w190p { width: 190% !important; }
.w195p { width: 195% !important; }
.w200p { width: 200% !important; }
.w205p { width: 205% !important; }
.w210p { width: 210% !important; }
.w215p { width: 215% !important; }
.w220p { width: 220% !important; }
.w225p { width: 225% !important; }
.w230p { width: 230% !important; }
.w235p { width: 235% !important; }
.w240p { width: 240% !important; }
.w245p { width: 245% !important; }
.w250p { width: 250% !important; }
.w255p { width: 255% !important; }
.w260p { width: 260% !important; }
.w265p { width: 265% !important; }
.w270p { width: 270% !important; }
.w275p { width: 275% !important; }
.w280p { width: 280% !important; }
.w285p { width: 285% !important; }
.w290p { width: 290% !important; }
.w295p { width: 295% !important; }
.w300p { width: 300% !important; }

.minw0  { min-width: 0px !important; }
.minw5  { min-width: 5px !important; }
.minw10 { min-width: 10px !important; }
.minw15 { min-width: 15px !important; }
.minw20 { min-width: 20px !important; }
.minw25 { min-width: 25px !important; }
.minw30 { min-width: 30px !important; }
.minw35 { min-width: 35px !important; }
.minw40 { min-width: 40px !important; }
.minw45 { min-width: 45px !important; }
.minw50 { min-width: 50px !important; }
.minw55 { min-width: 55px !important; }
.minw60 { min-width: 60px !important; }
.minw65 { min-width: 65px !important; }
.minw70 { min-width: 70px !important; }
.minw75 { min-width: 75px !important; }
.minw80 { min-width: 80px !important; }
.minw85 { min-width: 85px !important; }
.minw90 { min-width: 90px !important; }
.minw95 { min-width: 95px !important; }
.minw100 { min-width: 100px !important; }
.minw105 { min-width: 105px !important; }
.minw110 { min-width: 110px !important; }
.minw115 { min-width: 115px !important; }
.minw120 { min-width: 120px !important; }
.minw125 { min-width: 125px !important; }
.minw130 { min-width: 130px !important; }
.minw135 { min-width: 135px !important; }
.minw140 { min-width: 140px !important; }
.minw145 { min-width: 145px !important; }
.minw150 { min-width: 150px !important; }
.minw155 { min-width: 155px !important; }
.minw160 { min-width: 160px !important; }
.minw165 { min-width: 165px !important; }
.minw170 { min-width: 170px !important; }
.minw175 { min-width: 175px !important; }
.minw180 { min-width: 180px !important; }
.minw185 { min-width: 185px !important; }
.minw190 { min-width: 190px !important; }
.minw195 { min-width: 195px !important; }
.minw200 { min-width: 200px !important; }
.minw205 { min-width: 205px !important; }
.minw210 { min-width: 210px !important; }
.minw215 { min-width: 215px !important; }
.minw220 { min-width: 220px !important; }
.minw225 { min-width: 225px !important; }
.minw230 { min-width: 230px !important; }
.minw235 { min-width: 235px !important; }
.minw240 { min-width: 240px !important; }
.minw245 { min-width: 245px !important; }
.minw250 { min-width: 250px !important; }
.minw255 { min-width: 255px !important; }
.minw260 { min-width: 260px !important; }
.minw265 { min-width: 265px !important; }
.minw270 { min-width: 270px !important; }
.minw275 { min-width: 275px !important; }
.minw280 { min-width: 280px !important; }
.minw285 { min-width: 285px !important; }
.minw290 { min-width: 290px !important; }
.minw295 { min-width: 295px !important; }
.minw300 { min-width: 300px !important; }

.maxw0  { max-width: 0px !important; }
.maxw5  { max-width: 5px !important; }
.maxw10 { max-width: 10px !important; }
.maxw15 { max-width: 15px !important; }
.maxw20 { max-width: 20px !important; }
.maxw25 { max-width: 25px !important; }
.maxw30 { max-width: 30px !important; }
.maxw35 { max-width: 35px !important; }
.maxw40 { max-width: 40px !important; }
.maxw45 { max-width: 45px !important; }
.maxw50 { max-width: 50px !important; }
.maxw55 { max-width: 55px !important; }
.maxw60 { max-width: 60px !important; }
.maxw65 { max-width: 65px !important; }
.maxw70 { max-width: 70px !important; }
.maxw75 { max-width: 75px !important; }
.maxw80 { max-width: 80px !important; }
.maxw85 { max-width: 85px !important; }
.maxw90 { max-width: 90px !important; }
.maxw95 { max-width: 95px !important; }
.maxw100 { max-width: 100px !important; }
.maxw105 { max-width: 105px !important; }
.maxw110 { max-width: 110px !important; }
.maxw115 { max-width: 115px !important; }
.maxw120 { max-width: 120px !important; }
.maxw125 { max-width: 125px !important; }
.maxw130 { max-width: 130px !important; }
.maxw135 { max-width: 135px !important; }
.maxw140 { max-width: 140px !important; }
.maxw145 { max-width: 145px !important; }
.maxw150 { max-width: 150px !important; }
.maxw155 { max-width: 155px !important; }
.maxw160 { max-width: 160px !important; }
.maxw165 { max-width: 165px !important; }
.maxw170 { max-width: 170px !important; }
.maxw175 { max-width: 175px !important; }
.maxw180 { max-width: 180px !important; }
.maxw185 { max-width: 185px !important; }
.maxw190 { max-width: 190px !important; }
.maxw195 { max-width: 195px !important; }
.maxw200 { max-width: 200px !important; }
.maxw205 { max-width: 205px !important; }
.maxw210 { max-width: 210px !important; }
.maxw215 { max-width: 215px !important; }
.maxw220 { max-width: 220px !important; }
.maxw225 { max-width: 225px !important; }
.maxw230 { max-width: 230px !important; }
.maxw235 { max-width: 235px !important; }
.maxw240 { max-width: 240px !important; }
.maxw245 { max-width: 245px !important; }
.maxw250 { max-width: 250px !important; }
.maxw255 { max-width: 255px !important; }
.maxw260 { max-width: 260px !important; }
.maxw265 { max-width: 265px !important; }
.maxw270 { max-width: 270px !important; }
.maxw275 { max-width: 275px !important; }
.maxw280 { max-width: 280px !important; }
.maxw285 { max-width: 285px !important; }
.maxw290 { max-width: 290px !important; }
.maxw295 { max-width: 295px !important; }
.maxw300 { max-width: 300px !important; }

/* height short codes */

.h0 { height: 0px !important; }
.h5 { height: 5px !important; }
.h10 { height: 10px !important; }
.h15 { height: 15px !important; }
.h20 { height: 20px !important; }
.h25 { height: 25px !important; }
.h30 { height: 30px !important; }
.h35 { height: 35px !important; }
.h40 { height: 40px !important; }
.h45 { height: 45px !important; }
.h50 { height: 50px !important; }
.h55 { height: 55px !important; }
.h60 { height: 60px !important; }
.h65 { height: 65px !important; }
.h70 { height: 70px !important; }
.h75 { height: 75px !important; }
.h80 { height: 80px !important; }
.h85 { height: 85px !important; }
.h90 { height: 90px !important; }
.h95 { height: 95px !important; }
.h100 { height: 100px !important; }
.h105 { height: 105px !important; }
.h110 { height: 110px !important; }
.h115 { height: 115px !important; }
.h120 { height: 120px !important; }
.h125 { height: 125px !important; }
.h130 { height: 130px !important; }
.h135 { height: 135px !important; }
.h140 { height: 140px !important; }
.h145 { height: 145px !important; }
.h150 { height: 150px !important; }
.h155 { height: 155px !important; }
.h160 { height: 160px !important; }
.h165 { height: 165px !important; }
.h170 { height: 170px !important; }
.h175 { height: 175px !important; }
.h180 { height: 180px !important; }
.h185 { height: 185px !important; }
.h190 { height: 190px !important; }
.h195 { height: 195px !important; }
.h200 { height: 200px !important; }
.h205 { height: 205px !important; }
.h210 { height: 210px !important; }
.h215 { height: 215px !important; }
.h220 { height: 220px !important; }
.h225 { height: 225px !important; }
.h230 { height: 230px !important; }
.h235 { height: 235px !important; }
.h240 { height: 240px !important; }
.h245 { height: 245px !important; }
.h250 { height: 250px !important; }
.h255 { height: 255px !important; }
.h260 { height: 260px !important; }
.h265 { height: 265px !important; }
.h270 { height: 270px !important; }
.h275 { height: 275px !important; }
.h280 { height: 280px !important; }
.h285 { height: 285px !important; }
.h290 { height: 290px !important; }
.h295 { height: 295px !important; }
.h300 { height: 300px !important; }
.h80p { height: 80% !important; }
.h85p { height: 85% !important; }
.h90p { height: 90% !important; }
.h95p { height: 95% !important; }
.h100p { height: 100% !important; }

.mh0  { min-height: 0px !important; }
.mh5  { min-height: 5px !important; }
.mh10 { min-height: 10px !important; }
.mh15 { min-height: 15px !important; }
.mh20 { min-height: 20px !important; }
.mh25 { min-height: 25px !important; }
.mh30 { min-height: 30px !important; }
.mh35 { min-height: 35px !important; }
.mh40 { min-height: 40px !important; }
.mh45 { min-height: 45px !important; }
.mh50 { min-height: 50px !important; }
.mh55 { min-height: 55px !important; }
.mh60 { min-height: 60px !important; }
.mh65 { min-height: 65px !important; }
.mh70 { min-height: 70px !important; }
.mh75 { min-height: 75px !important; }
.mh80 { min-height: 80px !important; }
.mh85 { min-height: 85px !important; }
.mh90 { min-height: 90px !important; }
.mh95 { min-height: 95px !important; }
.mh100 { min-height: 100px !important; }
.mh105 { min-height: 105px !important; }
.mh110 { min-height: 110px !important; }
.mh115 { min-height: 115px !important; }
.mh120 { min-height: 120px !important; }
.mh125 { min-height: 125px !important; }
.mh130 { min-height: 130px !important; }
.mh135 { min-height: 135px !important; }
.mh140 { min-height: 140px !important; }
.mh145 { min-height: 145px !important; }
.mh150 { min-height: 150px !important; }
.mh155 { min-height: 155px !important; }
.mh160 { min-height: 160px !important; }
.mh165 { min-height: 165px !important; }
.mh170 { min-height: 170px !important; }
.mh175 { min-height: 175px !important; }
.mh180 { min-height: 180px !important; }
.mh185 { min-height: 185px !important; }
.mh190 { min-height: 190px !important; }
.mh195 { min-height: 195px !important; }
.mh200 { min-height: 200px !important; }
.mh205 { min-height: 205px !important; }
.mh210 { min-height: 210px !important; }
.mh215 { min-height: 215px !important; }
.mh220 { min-height: 220px !important; }
.mh225 { min-height: 225px !important; }
.mh230 { min-height: 230px !important; }
.mh235 { min-height: 235px !important; }
.mh240 { min-height: 240px !important; }
.mh245 { min-height: 245px !important; }
.mh250 { min-height: 250px !important; }
.mh255 { min-height: 255px !important; }
.mh260 { min-height: 260px !important; }
.mh265 { min-height: 265px !important; }
.mh270 { min-height: 270px !important; }
.mh275 { min-height: 275px !important; }
.mh280 { min-height: 280px !important; }
.mh285 { min-height: 285px !important; }
.mh290 { min-height: 290px !important; }
.mh295 { min-height: 295px !important; }
.mh300 { min-height: 300px !important; }
.mh305 { min-height: 305px !important; }
.mh310 { min-height: 310px !important; }
.mh315 { min-height: 315px !important; }
.mh320 { min-height: 320px !important; }
.mh325 { min-height: 325px !important; }
.mh330 { min-height: 330px !important; }
.mh335 { min-height: 335px !important; }
.mh340 { min-height: 340px !important; }
.mh345 { min-height: 345px !important; }
.mh350 { min-height: 350px !important; }
.mh355 { min-height: 355px !important; }
.mh360 { min-height: 360px !important; }
.mh365 { min-height: 365px !important; }
.mh370 { min-height: 370px !important; }
.mh375 { min-height: 375px !important; }
.mh380 { min-height: 380px !important; }
.mh385 { min-height: 385px !important; }
.mh390 { min-height: 390px !important; }
.mh395 { min-height: 395px !important; }
.mh400 { min-height: 400px !important; }

.maxh0  { max-height: 0px !important; }
.maxh5  { max-height: 5px !important; }
.maxh10 { max-height: 10px !important; }
.maxh15 { max-height: 15px !important; }
.maxh20 { max-height: 20px !important; }
.maxh25 { max-height: 25px !important; }
.maxh30 { max-height: 30px !important; }
.maxh35 { max-height: 35px !important; }
.maxh40 { max-height: 40px !important; }
.maxh45 { max-height: 45px !important; }
.maxh50 { max-height: 50px !important; }
.maxh55 { max-height: 55px !important; }
.maxh60 { max-height: 60px !important; }
.maxh65 { max-height: 65px !important; }
.maxh70 { max-height: 70px !important; }
.maxh75 { max-height: 75px !important; }
.maxh80 { max-height: 80px !important; }
.maxh85 { max-height: 85px !important; }
.maxh90 { max-height: 90px !important; }
.maxh95 { max-height: 95px !important; }
.maxh100 { max-height: 100px !important; }
.maxh105 { max-height: 105px !important; }
.maxh110 { max-height: 110px !important; }
.maxh115 { max-height: 115px !important; }
.maxh120 { max-height: 120px !important; }
.maxh125 { max-height: 125px !important; }
.maxh130 { max-height: 130px !important; }
.maxh135 { max-height: 135px !important; }
.maxh140 { max-height: 140px !important; }
.maxh145 { max-height: 145px !important; }
.maxh150 { max-height: 150px !important; }
.maxh155 { max-height: 155px !important; }
.maxh160 { max-height: 160px !important; }
.maxh165 { max-height: 165px !important; }
.maxh170 { max-height: 170px !important; }
.maxh175 { max-height: 175px !important; }
.maxh180 { max-height: 180px !important; }
.maxh185 { max-height: 185px !important; }
.maxh190 { max-height: 190px !important; }
.maxh195 { max-height: 195px !important; }
.maxh200 { max-height: 200px !important; }
.maxh205 { max-height: 205px !important; }
.maxh210 { max-height: 210px !important; }
.maxh215 { max-height: 215px !important; }
.maxh220 { max-height: 220px !important; }
.maxh225 { max-height: 225px !important; }
.maxh230 { max-height: 230px !important; }
.maxh235 { max-height: 235px !important; }
.maxh240 { max-height: 240px !important; }
.maxh245 { max-height: 245px !important; }
.maxh250 { max-height: 250px !important; }
.maxh255 { max-height: 255px !important; }
.maxh260 { max-height: 260px !important; }
.maxh265 { max-height: 265px !important; }
.maxh270 { max-height: 270px !important; }
.maxh275 { max-height: 275px !important; }
.maxh280 { max-height: 280px !important; }
.maxh285 { max-height: 285px !important; }
.maxh290 { max-height: 290px !important; }
.maxh295 { max-height: 295px !important; }
.maxh300 { max-height: 300px !important; }

/************* font sizes ****************/
.fs5px { font-size: 5px !important; }
.fs6px { font-size: 6px !important; }
.fs7px { font-size: 7px !important; }
.fs8px { font-size: 8px !important; }
.fs9px { font-size: 9px !important; }
.fs10px { font-size: 10px !important; }
.fs11px { font-size: 11px !important; }
.fs12px { font-size: 12px !important; }
.fs13px { font-size: 13px !important; }
.fs14px { font-size: 14px !important; }
.fs15px { font-size: 15px !important; }
.fs16px { font-size: 16px !important; }
.fs17px { font-size: 17px !important; }
.fs18px { font-size: 18px !important; }
.fs19px { font-size: 19px !important; }
.fs20px { font-size: 20px !important; }
.fs21px { font-size: 21px !important; }
.fs22px { font-size: 22px !important; }
.fs23px { font-size: 23px !important; }
.fs24px { font-size: 24px !important; }
.fs25px { font-size: 25px !important; }
.fs26px { font-size: 26px !important; }
.fs27px { font-size: 27px !important; }
.fs28px { font-size: 28px !important; }
.fs29px { font-size: 29px !important; }
.fs30px { font-size: 30px !important; }