.home-slider{
    .carousel-inner{
        .carousel-item{
            .carousel-caption{
                h1{
                    @media screen and (max-width: 1199px) and (min-width: 768px){
                        font-size: 42px;
                    }
                }
            }
        }
    }
    
}

//footer
.footer{
    .footer-icon{
        p{
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
    .download-app-wrap{
        display: flex;
        align-items: center;
        gap: 10px;
        a{
            display: block;
            img{
                width: 130px;
                height: auto;
                object-fit: contain;
            }
        }
    }
}

// counting-inner
.counting-inner{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: -12px;
        width: 1px;
        height: 250px;
        background-color: rgb(177, 177, 177);
    }
    &.no-counting{
        &::after{
            display: none;
        }
    }
    @media only screen and (max-width: 992px){
        &:after{
            display: none;
        }
    }
}

//our-team 
.our-team {
    .wokers-name{
        min-height: 560px;
        @media screen and (max-width: 1199px) and (min-width: 992px){
            min-height: 632px;
        }
        @media only screen and (max-width: 991px){
            min-height: auto;
        }
    }
}

//fssai-icon
.fssai-icon{
    img{
        width: 250px;
        margin: 0px auto;
    }
}

//food-business-services
.food-business-services{
    .service-body{
        display: flex;
        align-items: flex-start;
        @media only screen and (max-width: 767px){
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }
}

.button-design-header{
    .navbar-nav{
        .dropdown{
            .dropdown-menu{
                right: 0px;
                .nav-item{
                    a{
                        display: block;
                        button{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
